<template>
<section class="domain_content">
    <h1>
        Is There iOS version for GB WhatsApp?
    </h1>

    <p>
        Many people know there are some <a href="https://gbwhatsapk.chat/" class="inline-download" target="_blank">mod version app of WhatsApp</a> for Android. However, some of them using iPhone and trying to find the iOS version of GB WhatsApp. However, after looking through some websites ranking high in the Google Chrome, they still can't download GB WhatsApp for iPhone successfully.
    </p>
    <p class="center">
        ❗❗There is no official version of GB WhatsApp for iOS users ❗❗
    </p>

    <p class="pic-fit">
        <img alt="no official gbwhatsapp for ios" src="@/assets/iPhone11.webp" title="Guide to Use GB WhatsApp Anti-Ban Enhanced Version 2024 2" />
    </p>

    <p>
        Users cannot download GB WhatsApp directly from the Apple Store because it is a modified version of the official WhatsApp. However, you can download and install GB WhatsApp iOS X Android on your Android device, which replicates the iOS interface, but it does not allow you to run GB WhatsApp on an iPhone.
    </p>
    <p>
        For iPhone users, there is an option to install a GB WhatsApp alternative, but it involves jailbreaking the device. Jailbreaking is a complex process that introduces significant risks. Therefore, we do not recommend this method for installing GB WhatsApp alternatives on iOS.
    </p>

    <h2>
        Download GB WhatsApp For Android
    </h2>

    <div class="old-version-column">
        <div class="version-item">
            <div class="old-version-row">
                <img src="@/assets/shiny-logo.webp" class="head-img" alt="logo" />
                <div class="version-detail">
                    <h4>GB WhatsApp 18.10</h4>
                    <p><strong>Size:</strong> 77MB</p>
                    <p><strong>Last Updated:</strong> 2 Days ago</p>
                    <p><strong>Downloads:</strong> 1,000,000+</p>
                    <p><strong>System:</strong> Android</p>
                </div>
            </div>
            <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
                <img :src="downloadicon" alt="download" class="download-img">
                <div class="download-name">
                    Download
                </div>
            </div>
        </div>

    </div>

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';

export default {
    ...{
        "metaInfo": {
            "title": "Is GB WhatsApp Available for iPhone? Official Explanation",
            "meta": [{
                "name": "description",
                "content": "Some sites provide GB WhatsApp iOS version to download. However, does GB WhatsApp have an official iOS version? Here we'll give a explanation."
            }, {
                "name": "title",
                "content": "Is GB WhatsApp Available for iPhone? Official Explanation"
            }, {
                "property": "og:title",
                "content": "Is GB WhatsApp Available for iPhone? Official Explanation"
            }, {
                "property": "og:description",
                "content": "Some sites provide GB WhatsApp iOS version to download. However, does GB WhatsApp have an official iOS version? Here we'll give a explanation."
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.net/gbwhatsapp-ios/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },

        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },

        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
